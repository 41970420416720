// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import {
  Error, FormHeader, Loader, SetupCard,
} from "@fas/ui-core";
import { Box, Container } from "@mui/material";
import {
  InlineMultiSelectForm, SelectForm, SwitchFieldForm, TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { useDictionaryList } from "../../hoocks/useDictionary";
import Form from "../Form/Form";
import DropzoneAreaForm from "../AdvertiserInvoicesForm/DropzoneAreaForm";
import { FORM_KEY_SMU_BRAND } from "../../helpers/constants/smu";
import SelectFormWithDefaultValue from "../PayoutTermsFormContainer/SelectFormWithDefaultValue";
import validate from "./validate";
import { convertBytesToMbsOrKbs } from "../../helpers/generators/generators";

export type Props = {};

const getFileLimitExceedMessage = (): string => `File type not supported. Or file is too big. Size limit is ${convertBytesToMbsOrKbs(1024 * 150)}.`;

const SmuBrandFormContainer: StatelessFunctionalComponent<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_SMU_BRAND, id));
    }
  }, [id]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const [productCompanies, productCompaniesLoading] = useDictionaryList("smu", "productCompany");
  const [verticals, verticalsLoading] = useDictionaryList("smu", "vertical");
  const [countries, countriesLoading] = useDictionaryList("smu", "country");
  const [niches, nichesLoading] = useDictionaryList("smu", "niche");

  const isError: mixed = useSelector((state) => getFormField(state, FORM_KEY_SMU_BRAND, "isError"));

  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_SMU_BRAND} redirectOnSave="/smuBrands" validate={validate}>
      <FormHeader
        title={id ? `Edit brand: ${String(id)}` : "Create brand"}
        loading={loading}
        isActionVisible={!loading}
      >
        <SwitchFieldForm
          name="isActive"
          label="Is active"
        />
      </FormHeader>
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width="100%">
            <Loader loading={loading}>
              <SelectFormWithDefaultValue
                name="companyId"
                title="companyName"
                label="Product company name"
                options={productCompanies}
                loading={productCompaniesLoading}
              />
              <TextFieldForm
                name="domain"
                label="Domain"
              />
              <SelectForm
                name="vertical"
                label="Vertical"
                options={verticals}
                loading={verticalsLoading}
              />
              <SelectForm
                name="niche"
                label="Niche"
                options={niches}
                loading={nichesLoading}
              />
              {/* $FlowFixMe need add type for loading prop */}
              <InlineMultiSelectForm
                name="country"
                label="Countries"
                options={countries}
                loading={countriesLoading}
              />
              <TextFieldForm
                name="url"
                label="Url"
              />
            </Loader>
          </Box>
        </SetupCard>
        <SetupCard title="Banner">
          <Box width="100%">
            <Loader loading={loading}>
              <DropzoneAreaForm
                name="banner"
                maxFileSize={1024 * 150}
                getFileLimitExceedMessage={getFileLimitExceedMessage}
                showFileNamesInPreview={false}
              />
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default SmuBrandFormContainer;
