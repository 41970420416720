// @flow
import type { Node } from "react";
import React from "react";
import type { Theme } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DropzoneArea } from "@fas/ui-core";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type Classes = {
  dropzone: string,
  disabled: string,
  dropzoneIcon: string,
  dropzonePreviewContainer: string,
  dropzonePreviewImage: string,
}

const useStyles: ({ showRemoveButton: boolean }) => Classes = makeStyles((theme: Theme): * => ({
  dropzone: {
    minHeight: "auto",
    height: "100px",
    marginBottom: theme.spacing(4),
    borderRadius: theme.borderRadius,
    borderColor: theme.palette.borderColor,
    borderWidth: "1px",
    background: theme.palette.background.mainBg,
  },
  disabled: {
    backgroundColor: "#D4D9E6",
    color: "#8D96AE",
    opacity: 0.5,
  },
  dropzoneIcon: {
    display: "none",
  },
  dropzonePreviewContainer: {
    marginTop: 0,
    "&>*> .MuiDropzonePreviewList-removeButton": {
      visibility: ({ showRemoveButton }) => (showRemoveButton ? "visible" : "hidden"),
    },
  },
  dropzonePreviewImage: {
    flexBasis: "auto",
    maxWidth: "none",
    "& > p": {
      overflowWrap: "break-word",
      width: 100,
    },
  },
}));

export type Props = {|
  name: string,
  error: string,
  value: *,
  onChange: (*) => *,
  getFileLimitExceedMessage?: () => string,
  getDropRejectMessage?: () => string,
  fetchFile?: (string) => Promise<File>,
  maxFileSize: number,
  acceptedFiles: string[],
  dropzoneText: string,
  disabled: boolean,
  filesLimit: number,
  showRemoveButton: boolean,
  showFileNamesInPreview: boolean,
  previewText: string,
|}

function DropzoneAreaForm({
  error,
  name,
  value,
  onChange,
  maxFileSize,
  acceptedFiles,
  dropzoneText,
  previewText,
  disabled,
  filesLimit,
  showRemoveButton,
  ...props
}: Props): Node {
  const classes: Classes = useStyles({ showRemoveButton });
  return (
    <FormControl fullWidth variant="outlined" disabled>
      <DropzoneArea
        {...props}
        dropzoneProps={{ disabled }}
        classes={{
          root: `${classes.dropzone} ${disabled ? classes.disabled : ""}`,
          icon: classes.dropzoneIcon,
        }}
        previewGridClasses={{
          container: classes.dropzonePreviewContainer,
          item: classes.dropzonePreviewImage,
        }}
        dropzoneText={dropzoneText}
        previewText={previewText}
        initialFiles={value ? [value] : []}
        showAlerts={["error"]}
        alertSnackbarProps={{ anchorOrigin: { horizontal: "center", vertical: "bottom" } }}
        filesLimit={filesLimit}
        maxFileSize={maxFileSize}
        acceptedFiles={acceptedFiles}
        onChange={(files) => {
          const [file = null] = files;
          onChange(filesLimit === 1 ? file : files);
        }}
        showPreviewsInDropzone={false}
        showPreviews
        data-testid={name}
      />
      <FormHelperText error>
        {error}
      </FormHelperText>
    </FormControl>
  );
}

DropzoneAreaForm.defaultProps = {
  onChange: () => {},
  error: "",
  value: "",
  filesLimit: 1,
  disabled: false,
  showRemoveButton: false,
  showFileNamesInPreview: true,
  previewText: "File",
  maxFileSize: 1024 * 1024 * 5,
  acceptedFiles: ["image/jpeg", "image/png"],
  dropzoneText: "Drag and drop a file here or click",
};

export default withFormField(DropzoneAreaForm);
