// @flow
export const SET_SORT_MANAGE_AFFILIATE: "SET_SORT_MANAGE_AFFILIATE" = "SET_SORT_MANAGE_AFFILIATE";
export const SET_FILTER_MANAGE_AFFILIATE: "SET_FILTER_MANAGE_AFFILIATE" = "SET_FILTER_MANAGE_AFFILIATE";
export const DELETE_FILTER_MANAGE_AFFILIATE: "DELETE_FILTER_MANAGE_AFFILIATE" = "DELETE_FILTER_MANAGE_AFFILIATE";
export const SET_SELECTED_ROWS_MANAGE_AFFILIATE: "SET_SELECTED_ROWS_MANAGE_AFFILIATE" = "SET_SELECTED_ROWS_MANAGE_AFFILIATE";
export const CLEANUP_TABLE_MANAGE_AFFILIATE: "CLEANUP_TABLE_MANAGE_AFFILIATE" = "CLEANUP_TABLE_MANAGE_AFFILIATE";
export const GET_MANAGE_AFFILIATE_DATA: "GET_MANAGE_AFFILIATE_DATA" = "GET_MANAGE_AFFILIATE_DATA";
export const SET_FILTERS_MANAGE_AFFILIATES: "SET_FILTERS_MANAGE_AFFILIATES" = "SET_FILTERS_MANAGE_AFFILIATES";
export const SET_PERSONAL_INFO_FIELD: "SET_PERSONAL_INFO_FIELD" = "SET_PERSONAL_INFO_FIELD";
export const CLEAR_PERSONAL_INFO: "CLEAR_PERSONAL_INFO" = "CLEAR_PERSONAL_INFO";
export const GET_PERSONAL_INFO_LISTS: "GET_PERSONAL_INFO_LISTS" = "GET_PERSONAL_INFO_LISTS";
export const SET_ERRORS_PERSONAL_INFO: "SET_ERRORS_PERSONAL_INFO" = "SET_ERRORS_PERSONAL_INFO";
export const CLEAR_ERRORS_PERSONAL_INFO: "CLEAR_ERRORS_PERSONAL_INFO" = "CLEAR_ERRORS_PERSONAL_INFO";
export const IS_AFFILIATE_SAVED: "IS_AFFILIATE_SAVED" = "IS_AFFILIATE_SAVED";
export const SET_MANAGE_AFFILIATE_ID: "SET_MANAGE_AFFILIATE_ID" = "SET_MANAGE_AFFILIATE_ID";
export const SET_MANAGE_AFFILIATE_CURRENT_TABLET: "SET_MANAGE_AFFILIATE_CURRENT_TABLET" = "SET_MANAGE_AFFILIATE_CURRENT_TABLET";
export const SET_PERSONAL_INFO: "SET_PERSONAL_INFO" = "SET_PERSONAL_INFO";
export const ADD_IS_ACTIVE_MANAGE_AFFILIATE: "ADD_IS_ACTIVE_MANAGE_AFFILIATE" = "ADD_IS_ACTIVE_MANAGE_AFFILIATE";
export const REMOVE_IS_ACTIVE_MANAGE_AFFILIATE: "REMOVE_IS_ACTIVE_MANAGE_AFFILIATE" = "REMOVE_IS_ACTIVE_MANAGE_AFFILIATE";
export const CLEAR_IS_ACTIVES_MANAGE_AFFILIATE: "CLEAR_IS_ACTIVES_MANAGE_AFFILIATE" = "CLEAR_IS_ACTIVES_MANAGE_AFFILIATE";
export const SET_LOADER_MANAGE_AFFILIATE: "SET_LOADER_MANAGE_AFFILIATE" = "SET_LOADER_MANAGE_AFFILIATE";
export const SET_PAYMENT_INFO_FIELD: "SET_PAYMENT_INFO_FIELD" = "SET_PAYMENT_INFO_FIELD";
export const CLEAR_PAYMENT_INFO: "CLEAR_PAYMENT_INFO" = "CLEAR_PAYMENT_INFO";
export const SET_ERRORS_PAYMENT_INFO: "SET_ERRORS_PAYMENT_INFO" = "SET_ERRORS_PAYMENT_INFO";
export const CLEAR_ERRORS_PAYMENT_INFO: "CLEAR_ERRORS_PAYMENT_INFO" = "CLEAR_ERRORS_PAYMENT_INFO";
export const SET_PAYMENT_INFO: "SET_PAYMENT_INFO" = "SET_PAYMENT_INFO";
export const SET_TEMPLATE_HEADERS: "SET_TEMPLATE_HEADERS" = "SET_TEMPLATE_HEADERS";
export const SET_TEMPLATES_LIST: "SET_TEMPLATES_LIST" = "SET_TEMPLATES_LIST";
export const SET_MESSAGES: "SET_MESSAGES" = "SET_MESSAGES";
export const IS_PERSONAL_INFO_FETCHED: "IS_PERSONAL_INFO_FETCHED" = "IS_PERSONAL_INFO_FETCHED";
export const SET_FETCHED_MANAGERS: "SET_FETCHED_MANAGERS" = "SET_FETCHED_MANAGERS";
export const SWITCH_CHANGE_AUTHOR_MODAL_OPENED: "SWITCH_CHANGE_AUTHOR_MODAL_OPENED" = "SWITCH_CHANGE_AUTHOR_MODAL_OPENED";
export const CHANGE_IS_MANAGER_LIST_LOADED: "CHANGE_IS_MANAGER_LIST_LOADED" = "CHANGE_IS_MANAGER_LIST_LOADED";
export const SET_MANAGERS_LIST: "SET_MANAGERS_LIST" = "SET_MANAGERS_LIST";
export const SET_AFF_TYPE: "SET_AFF_TYPE" = "SET_AFF_TYPE";
export const GET_MANAGE_AFFILIATES_DATA_SAGA: "GET_MANAGE_AFFILIATES_DATA_SAGA" = "GET_MANAGE_AFFILIATES_DATA_SAGA";
export const GET_PERSONAL_INFO_LISTS_SAGA: "GET_PERSONAL_INFO_LISTS_SAGA" = "GET_PERSONAL_INFO_LISTS_SAGA";
export const SAVE_AFFILIATE_SAGA: "SAVE_AFFILIATE_SAGA" = "SAVE_AFFILIATE_SAGA";
export const GET_AFFILIATE_SAGA: "GET_AFFILIATE_SAGA" = "GET_AFFILIATE_SAGA";
export const UPDATE_AFFILIATE_SAGA: "UPDATE_AFFILIATE_SAGA" = "UPDATE_AFFILIATE_SAGA";
export const BULK_CHANGE_AFFILIATE_STATUS: "BULK_CHANGE_AFFILIATE_STATUS" = "BULK_CHANGE_AFFILIATE_STATUS";
export const UPDATE_PAYMENT_INFO_SAGA: "UPDATE_PAYMENT_INFO_SAGA" = "UPDATE_PAYMENT_INFO_SAGA";
export const CHANGE_AFFILIATE_STATUS_SAGA: "CHANGE_AFFILIATE_STATUS_SAGA" = "CHANGE_AFFILIATE_STATUS_SAGA";
export const CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA: "CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA" = "CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA";
export const LOGIN_AS_AFFILIATE_SAGA: "LOGIN_AS_AFFILIATE_SAGA" = "LOGIN_AS_AFFILIATE_SAGA";
export const GENERATE_AUTO_LOGIN_LINK_SAGA: "GENERATE_AUTO_LOGIN_LINK_SAGA" = "GENERATE_AUTO_LOGIN_LINK_SAGA";
export const GET_TEMPLATES_LIST_SAGA: "GET_TEMPLATES_LIST_SAGA" = "GET_TEMPLATES_LIST_SAGA";
export const GET_AUTHOR_LIST_SAGA: "GET_AUTHOR_LIST_SAGA" = "GET_AUTHOR_LIST_SAGA";
export const BULK_CHANGE_AUTHOR_SAGA: "BULK_CHANGE_AUTHOR_SAGA" = "BULK_CHANGE_AUTHOR_SAGA";
export const GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA: "GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA" = "GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA";
export const SWITCH_CHANGE_STATUS_MODAL_OPENED: "SWITCH_CHANGE_STATUS_MODAL_OPENED" = "SWITCH_CHANGE_STATUS_MODAL_OPENED";
export const INCREMENT_SUBMIT_COUNT: "INCREMENT_SUBMIT_COUNT" = "INCREMENT_SUBMIT_COUNT";

export const CPA_AFFILIATES_LIST_TABLE: "CPA_AFFILIATES_LIST_TABLE" = "CPA_AFFILIATES_LIST_TABLE";
export const COPY_PAYMENT_DETAILS_BY_ID_SAGA: "COPY_PAYMENT_DETAILS_BY_ID_SAGA" = "COPY_PAYMENT_DETAILS_BY_ID_SAGA";
export const GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA: "GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA" = "GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA";
export const SET_DICTIONARY_LIST: "SET_DICTIONARY_LIST" = "SET_DICTIONARY_LIST";

export const COPY_PAYMENT_DETAILS_INFO: string = "If the affiliate’s payment details were linked to other affiliates through the Copy payment details feature, this connection will be terminated.";
