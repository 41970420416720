// @flow
import {
  call,
  select,
  takeEvery,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { GENERATE_AUTO_LOGIN_LINK_SAGA, CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import { generateLoginLink } from "../../services/manageAffiliateApi";
import { copyToClipboard } from "../../helpers/generators/generators";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

export function* makeFetch(): Saga<void> {
  const rows: CpaAffiliatesListItem[] = yield select(getTableSelections, CPA_AFFILIATES_LIST_TABLE);
  try {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, true));
    const { id }: CpaAffiliatesListItem = rows[0];

    if (id) {
      const { data } = yield call(generateLoginLink, id, "affiliate");
      copyToClipboard(data.linkAutologin);

      yield put(addNotification({ message: "Autologin Link copied successfully", severity: "success" }));
    }
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, false));
  }
}

export default function* watchGenerateAutoLoginLinkSaga(): Saga<void> {
  // todo add event type here
  yield takeEvery(GENERATE_AUTO_LOGIN_LINK_SAGA, (makeFetch: Function));
}
