// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useState,
  type Node,
} from "react";
import environment from "environment";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import DocumentTitle from "react-document-title";
import {
  Loader,
  AccessDenied,
} from "@fas/ui-core";
import { userApiService } from "./services/userApi";
import AppHeader from "./components/AppHeader";
import Dashboard from "./pages/Dashboard";
import ManageAffiliate from "./pages/ManageAffiliate";
import CreateAffiliate from "./pages/CreateAffiliate";
import CpaOffer from "./pages/CpaOfferIndex";
import CpaOfferForm from "./pages/CpaOffer";
import CpaOfferCampaigns from "./pages/CpaOfferCampaigns";
import ErrorPage from "./pages/ErrorPage";
import "./App.css";
import TopOffers from "./pages/TopOffers";
import Subrates from "./pages/Subrates";
import SubratesIndex from "./containers/SubratesIndex";
import SubrateInfo from "./containers/SubrateInfo";
import SubrateForm from "./containers/SubrateForm";
import PaymentInfo from "./pages/PaymentInfo";
import PrivatRoute from "./components/App";
import TopOffersForm from "./pages/TopOffersForm/TopOffersForm";
import PayoutTermsCreate from "./pages/PayoutTermsCreate";
import SmuDepartments from "./pages/SmuDepartments";
import SmuDepartmentsForm from "./pages/SmuDepartmentsForm";
import SmuBrandList from "./pages/SmuBrandList";
import SmuBrandForm from "./pages/SmuBrandForm";
import "typeface-roboto";
import PayoutTerms from "./pages/PayoutTerms/PayoutTerms";
import ProductCompany from "./pages/ProductCompany/ProductCompany";
import ProductCompanyForm from "./pages/ProductCompanyForm/ProductCompanyForm";
import PotentialPartner from "./pages/PotentialPartner/PotentialPartner";
import PotentialPartnerForm from "./pages/PotentialPartnerForm/PotentialPartnerForm";
import UtmCampaignDictionary from "./pages/UtmCampaignDictionary";
import AdvertiserInvoicesFormPage from "./pages/AdvertiserInvoicesForm/AdvertiserInvoicesForm";
import AdvertiserInvoicesPage from "./pages/AdvertiserInvoices";
import CrmMails from "./pages/CrmMails";
import CrmTemplates from "./pages/CrmTemplates";
import CrmTemplatesForm from "./pages/CrmTemplatesForm";
import Affiliates from "./modules/Affiliates";

const App = (): Node => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userApiService.getUserInfo(environment.endpoints.get.login)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, []);

  return (
    <div className="App">
      <DocumentTitle title="CPA Admin Panel">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={{
            ...theme,
            components: {
              ...theme.components,
              MuiOutlinedInput: {
                styleOverrides: {
                  ...theme.components.MuiOutlinedInput,
                  root: {
                    ...theme.components.MuiOutlinedInput.root,
                    "&.Mui-disabled": {
                      backgroundColor: "#D4D9E6",
                      color: "#8D96AE",
                      opacity: 0.5,
                    },
                    "&.Mui-readOnly": {
                      backgroundColor: "#f6f8ff",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                        borderWidth: 1,
                      },
                    },
                  },
                },
              },
            },
          }}
          >
            <>
              {loading && <Loader loading size={35} mt={37} />}
              {!loading && (
                <BrowserRouter>
                  <AppHeader />
                  <Routes>

                    <Route index path="/" element={<Navigate to="/dashboard" />} />

                    <Route
                      path="/cpaOffers/campaigns"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/template/cpaOffersCampaign/filter", method: "POST" }]}
                          component={CpaOfferCampaigns}
                        />
                      )}
                    />
                    <Route
                      path="/cpaOffers"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/offer/list", method: "GET" }]}
                          component={CpaOffer}
                        />
                      )}
                    />

                    <Route
                      path="/cpaOffers/add"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/offer", method: "POST" }]}
                          component={CpaOfferForm}
                        />
                      )}
                    />
                    <Route
                      path="/cpaOffers/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/offer", method: "POST" }]}
                          component={CpaOfferForm}
                        />
                      )}
                    />
                    <Route
                      path="/cpaOffers/:id/clone"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/offer", method: "POST" }]}
                          component={CpaOfferForm}
                        />
                      )}
                    />

                    <Route
                      path="/dashboard"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/dashboard", method: "GET" }]}
                          component={Dashboard}
                        />
                      )}
                    />
                    <Route
                      path="/index"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/dashboard", method: "GET" }]}
                          component={Dashboard}
                        />
                      )}
                    />

                    <Route
                      path="/manageAffiliate"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/affiliate", method: "GET" }]}
                          component={ManageAffiliate}
                        />
                      )}
                    />

                    <Route
                      path="/affiliates/*"
                      element={(
                        <PrivatRoute
                          component={Affiliates}
                          permissions={["getUserMe"]}
                        />
                      )}
                    />

                    <Route
                      path="/manageAffiliate/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]}
                          component={(props) => <CreateAffiliate {...props} mode="add" />}
                        />
                      )}
                    />

                    <Route
                      path="/manageAffiliate/edit/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/affiliate", method: "POST" }]}
                          component={(props) => <CreateAffiliate {...props} mode="edit" />}
                        />
                      )}
                    />
                    <Route
                      path="/manageAffiliate/edit/:id/:tab"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/affiliate", method: "POST" }]}
                          component={(props) => <CreateAffiliate {...props} mode="edit" />}
                        />
                      )}
                    />

                    <Route
                      path="/topOffers"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/topOffers/filters", method: "POST" }]}
                          component={TopOffers}
                        />
                      )}
                    />
                    <Route
                      path="/topOffers/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/topOffers", method: "POST" }]}
                          component={TopOffersForm}
                        />
                      )}
                    />
                    <Route
                      path="/topOffers/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/topOffers", method: "POST" }]}
                          component={TopOffersForm}
                        />
                      )}
                    />

                    <Route
                      path="/payments/paymentInfo"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/affiliate", method: "POST" }]}
                          component={PaymentInfo}
                        />
                      )}
                    />

                    <Route
                      path="/subrates"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/dictionary/subrate", method: "POST" }]}
                          component={Subrates}
                        />
                      )}
                    >
                      <Route
                        path="/subrates"
                        element={<SubratesIndex />}
                      />
                      <Route
                        path="/subrates/create"
                        element={<SubrateForm />}
                      />
                      <Route
                        path="/subrates/edit/:id"
                        element={<SubrateForm />}
                      />
                      <Route
                        path="/subrates/clone/:id"
                        element={<SubrateForm />}
                      />
                      <Route
                        path="/subrates/info"
                        element={<SubrateInfo />}
                      />
                    </Route>

                    <Route
                      path="/payoutTerms/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/payoutTerms", method: "POST" }]}
                          component={PayoutTermsCreate}
                        />
                      )}
                    />
                    <Route
                      path="/payoutTerms/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/payoutTerms", method: "POST" }]}
                          component={PayoutTermsCreate}
                        />
                      )}
                    />

                    <Route
                      path="/payoutTerms"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/payoutTerms/filter", method: "POST" }]}
                          component={PayoutTerms}
                        />
                      )}
                    />

                    <Route
                      path="/smuDepartments"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/departments", method: "GET" }]}
                          component={SmuDepartments}
                        />
                      )}
                    />
                    <Route
                      path="/smuDepartments/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/departments", method: "GET" }]}
                          component={SmuDepartmentsForm}
                        />
                      )}
                    />

                    <Route
                      path="/smuBrands"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/brands", method: "GET" }]}
                          component={SmuBrandList}
                        />
                      )}
                    />
                    <Route
                      path="/smuBrands/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/brands", method: "GET" }]}
                          component={SmuBrandForm}
                        />
                      )}
                    />
                    <Route
                      path="/smuBrands/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/brands", method: "GET" }]}
                          component={SmuBrandForm}
                        />
                      )}
                    />

                    <Route
                      path="/sumProductCompany"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/companies", method: "GET" }]}
                          component={ProductCompany}
                        />
                      )}
                    />
                    <Route
                      path="/sumProductCompany/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/companies", method: "GET" }]}
                          component={ProductCompanyForm}
                        />
                      )}
                    />
                    <Route
                      path="/sumProductCompany/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/bank/companies", method: "GET" }]}
                          component={ProductCompanyForm}
                        />
                      )}
                    />

                    <Route
                      path="/potentialPartner"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/potentialPartner", method: "GET" }]}
                          component={PotentialPartner}
                        />
                      )}
                    />
                    <Route
                      path="/potentialPartner/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/potentialPartner", method: "GET" }]}
                          component={PotentialPartnerForm}
                        />
                      )}
                    />
                    <Route
                      path="/potentialPartner/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/potentialPartner", method: "GET" }]}
                          component={PotentialPartnerForm}
                        />
                      )}
                    />

                    <Route
                      path="/advertiserInvoices/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]}
                          component={AdvertiserInvoicesFormPage}
                        />
                      )}
                    />
                    <Route
                      path="/advertiserInvoices/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]}
                          component={AdvertiserInvoicesFormPage}
                        />
                      )}
                    />

                    <Route
                      path="/advertiserInvoices"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/cpa/advertiser/invoices", method: "GET" }]}
                          component={AdvertiserInvoicesPage}
                        />
                      )}
                    />
                    <Route
                      path="/redefinedUtmCampaign"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/generatedUtmCampaign", method: "GET" }]}
                          component={UtmCampaignDictionary}
                        />
                      )}
                    />

                    <Route path="/crm/mails" element={<CrmMails />} />
                    <Route path="/crm/mails/create" element={<CrmMails />} />
                    <Route path="/crm/mails/:id" element={<CrmMails />} />

                    <Route
                      path="/crm/templates"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/template/mailTemplate/filter", method: "POST" }]}
                          component={CrmTemplates}
                        />
                      )}
                    />
                    <Route
                      path="/crm/templates/create"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/crm/mailTemplate", method: "POST" }]}
                          component={CrmTemplatesForm}
                        />
                      )}
                    />
                    <Route
                      path="/crm/templates/:id"
                      element={(
                        <PrivatRoute
                          permissions={[{ path: "/api/v1/crm/mailTemplate", method: "GET" }]}
                          component={CrmTemplatesForm}
                        />
                      )}
                    />

                    <Route path="/accessDenied" element={<AccessDenied />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </BrowserRouter>
              )}
            </>
          </ThemeProvider>
        </StyledEngineProvider>
      </DocumentTitle>
    </div>
  );
};

export default App;
