import type React from "react";
import { useEffect, useState } from "react";
import type { GroupFeature } from "../Affiliates/types";
import useTenantId from "../Affiliates/useTenantId";
import useAllowedOperationIds from "../Affiliates/useAllowedOperationIds";

type CProps = { slots: GroupFeature[], isLoading: boolean };

const SlotResolver = ({ children }: { children: (p: CProps) => React.ReactNode }) => {
  const tenant = useTenantId();
  useAllowedOperationIds();
  const [dynamicSlots, setDynamicSlots] = useState<GroupFeature[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDynamicSlots = async () => {
      setIsLoading(true);
      try {
        const slotsModule = await import(`../tenants/${tenant}/slots`);
        setDynamicSlots(slotsModule.default || []);
      }
      catch (error) {
        setDynamicSlots([]);
      }
      setIsLoading(false);
    };

    loadDynamicSlots();
  }, [tenant]);

  return (
    <>{children({ slots: dynamicSlots, isLoading })}</>
  );
};

export default SlotResolver;
