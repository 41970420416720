import useTenantId from "./useTenantId";
import { userService } from "../../services/user";
import { userApiService } from "../../services/userApi";

const emptyArray: string[] = [];

export default function useAllowedOperationIds() {
  const tenantId = useTenantId();
  userService.setDynamicPermissions(userApiService.allowedTenantOperationIds?.[tenantId] || emptyArray);
}
