// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import {
  Error, FormHeader, Loader, SetupCard,
} from "@fas/ui-core";
import { Box, Container } from "@mui/material";
import {
  SwitchFieldForm, TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { useDictionaryList } from "../../hoocks/useDictionary";
import Form from "../Form/Form";
import { FORM_KEY_SMU_PRODUCT_COMPANY } from "../../helpers/constants/productCompanyForm";
import validate from "./validate";
import MultiSelectForm from "./MultiselectForm";

export type Props = {};

const ProductCompanyFormContainer: StatelessFunctionalComponent<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_SMU_PRODUCT_COMPANY, id));
    }
  }, [id]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const [companies, companiesLoading] = useDictionaryList("smu", "company");

  const isError: mixed = useSelector((state) => getFormField(state, FORM_KEY_SMU_PRODUCT_COMPANY, "isError"));

  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_SMU_PRODUCT_COMPANY} redirectOnSave="/sumProductCompany" validate={validate}>
      <FormHeader
        title={id ? `Edit product company: ${String(id)}` : "Create product company"}
        loading={loading}
        isActionVisible={!loading}
      >
        <SwitchFieldForm
          name="isActive"
          label="Is active"
        />
      </FormHeader>
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width="100%">
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Product company name"
              />
              <MultiSelectForm
                name="performers"
                label="Marketing company"
                options={companies}
                loading={companiesLoading}
              />
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default ProductCompanyFormContainer;
