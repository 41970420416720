import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const textFieldProps = {
  sx: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(0, 0, 0, 0.23)" },
  },
  inputProps: {
    min: 0,
    max: 99999999.99,
    sx: {
      fontSize: "0.8rem",
    },
  },
};
export const percentageTextFieldProps = {
  ...textFieldProps,
  inputProps: {
    ...textFieldProps.inputProps,
    min: 0,
    max: 100,
  },
};

export function getValue(value: undefined | null | string | number, defaultValue: string, postfix = "") {
  const result = (postfix ? `${value} ${postfix}` : value);
  return value === null || value === undefined ? defaultValue : result;
}

type NonEmpty<T> = T extends null | undefined | "" ? never : T;

type RemoveEmpty<T extends object> = {
  [K in keyof T as NonEmpty<T[K]> extends never ? never : K]: NonEmpty<T[K]>;
};

export function removeEmpty<T extends object>(obj: T): RemoveEmpty<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== "")
  ) as RemoveEmpty<T>;
}

export function getFlatObjectDifference<T>(obj1: T, obj2: T) {
  const difference: Partial<T> = {};
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      difference[key] = obj1[key];
    }
  }
  return difference;
}

export function getCurrentDate() {
  return new Date();
}

export function toHumanReadableDateTime(date: Date) {
  const dateOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  } as const;

  return date.toLocaleString("en-GB", dateOptions);
}

export function toHumanReadableDateTimeUTC(date: Date) {
  dayjs.extend(utc);
  return dayjs.utc(date).format("DD/MM/YYYY HH:mm:ss");
}

export function prepareUrlCompany(url: string | null) {
  if (!url) return "";
  return (!url.startsWith("http://") && !url.startsWith("https://")) ? `https://${url}` : url;
}

export function toNumber(value: number | string | null): number | null {
  return value === null || value === "" ? null : Number(value);
}

export const getPrefixPostfix = (inputString: string, keyword: string): { prefix: string; postfix: string } | null => {
  const index = inputString.indexOf(keyword.trim());

  if (index !== -1) {
    const prefix = inputString.slice(0, index).trim();
    const postfix = inputString.slice(index + keyword.length).trim();

    return { prefix, postfix };
  }
  return null;
};
