// @flow
import { user as User, type User as UserType } from "@fas/ui-framework";
import type { Permission } from "@fas/ui-framework/lib/services/user/types/user.types";
import { apiService } from "../request";

class UserService extends User {
  _dynamicPermissions: { [key: string]: true } = {};

  setUser(user: UserType | null) {
    super.setUser(user);

    const {
      apiKey,
    }: UserType = user || {};

    apiService.setOptions(apiKey, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  setDynamicPermissions(permissions: string[]) {
    this._dynamicPermissions = permissions.reduce((acc, key) => ({ ...acc, [key]: true }), {});
  }

  can(permissions: Permission[]): boolean {
    const isDynamic = permissions.every((permission): boolean => typeof permission === "string" && this._dynamicPermissions[permission]);
    const result = isDynamic || super.can(permissions);
    return result;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const userService: UserService = new UserService();
