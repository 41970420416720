// @flow
/* eslint-disable import/max-dependencies */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  IconButton,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  CircularProgress,
  debounce,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { setErrors, removeError } from "@fas/ui-framework/lib/redux/actions/errors";
import { getOffersCountries, getPostbackOffers } from "../../../../../../../../services/manageAffiliateApi";
import {
  getPostbackEventsSaga,
  openEventPostbackModal,
  savePostbackEventSaga,
  changeCurrentEventPostback,
} from "../../../../../../../../actions/eventsPostback";
import {
  mapCountry,
  mapOfferId,
  sanitizeFieldCountry,
  sanitizeOfferId,
} from "../../utils";
import {
  getIsAddEventPostbackModalOpen,
  getCurrentEventPostback,
} from "../../../../../../../../selectors/manageAffiliate";
import { sanitizeCountry } from "../../../Postbacks/utils";

type Props = {
  lists: Object,
  saveEvent: () => any,
  getEvents: () => any,
  fetchOffers: () => void,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  dialogWrapper: {
    width: 700,
  },
  dialogContent: {
    background: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fieldSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  input: {
    background: theme.palette.background.mainBg,
  },
}));

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  isAddEventPostbackModalOpen: getIsAddEventPostbackModalOpen(state),
  currentEventPostback: getCurrentEventPostback(state),
});

const mapDispatchToProps = (dispatch) => ({
  setNewErrors: (val) => dispatch(setErrors(val)),
  clearError: (val) => dispatch(removeError(val)),
  saveEvent: (fields) => dispatch(savePostbackEventSaga(fields)),
  getEvents: () => dispatch(getPostbackEventsSaga()),
  handleOpenEventPostbackModal: (val) => dispatch(openEventPostbackModal(val)),
  handleChangeCurrentEventPostback: (val) => dispatch(changeCurrentEventPostback(val)),
});

const FieldsForm = ({
  lists = {},
  saveEvent,
  errors,
  setNewErrors,
  isAddEventPostbackModalOpen,
  handleOpenEventPostbackModal,
  currentEventPostback,
  handleChangeCurrentEventPostback,
  clearError,
}: Props) => {
  const classes = useStyles();
  const errorsObject = errors.toJS();

  const [searchString, setSearchString] = useState("");
  const [isOffersLoading, setOffersLoading] = useState(false);
  const [isCountriesLoading, setCountriesLoading] = useState(false);
  const [fields, setFields] = useState({
    eventType: "",
    event: "",
    offerId: {
      id: "",
      name: "",
    },
    country: [],
    eventUrl: "",
  });
  const [offers, setOffers] = useState([{ id: "all", name: "all" }]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (currentEventPostback) {
      setFields({
        eventType: currentEventPostback.eventType,
        event: currentEventPostback.event,
        offerId: mapOfferId(currentEventPostback),
        country: mapCountry(currentEventPostback),
        eventUrl: currentEventPostback.eventUrl,
      });
    }
  }, [currentEventPostback]);

  const fetchPostbackOffers = async (val) => {
    if (val.length < 3) {
      return;
    }
    setOffersLoading(true);
    const result = await getPostbackOffers(val);
    setOffers(result.data.data);
    setOffersLoading(false);
  };
  const fetchPostbackCountries = async (val) => {
    if (val.length < 3) {
      return;
    }
    setCountriesLoading(true);
    const result = await getOffersCountries(val);
    setCountries(result.data.countries);
    setCountriesLoading(false);
  };

  useEffect(() => {
    fetchPostbackOffers(searchString);
  }, [searchString]);
  useEffect(() => {
    fields.offerId && fetchPostbackCountries(fields.offerId.id);
  }, [fields.offerId]);

  const handleOnClose = () => {
    setNewErrors({});
    handleChangeCurrentEventPostback(null);
    setFields({
      eventType: "",
      event: "",
      offerId: {
        id: 0,
        name: "",
      },
      country: [],
      eventUrl: "",
    });
    handleOpenEventPostbackModal(false);
  };
  const handleSave = () => {
    const sanitizedFields = {
      ...fields,
      offerId: sanitizeOfferId(fields.offerId.id),
      country: sanitizeFieldCountry(fields.country),
    };
    const result = currentEventPostback && currentEventPostback.eventId
      ? { ...sanitizedFields, eventId: currentEventPostback.eventId }
      : sanitizedFields;

    return saveEvent(result);
  };
  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
    if (errorsObject[e.target.name]) {
      clearError([e.target.name]);
    }
  };
  const handleChangeEventType = (e, item) => {
    const eventList = lists.events[item];
    setFields({
      ...fields,
      eventType: item,
      event: eventList[0],
    });
    if (errorsObject.eventType) {
      clearError(["eventType"]);
    }
  };
  const handleChangeOffers = (e) => {
    const { value } = e.target;
    debounce(setSearchString, 1000)(value);
  };
  const handleSelectOffer = (e, item) => {
    setFields({
      ...fields,
      offerId: item,
      country: [],
    });
    clearError([e.target.name]);
  };

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };
  const zeroSpacing = { marginLeft: 0, marginRight: 0 };

  useEffect(() => () => setOffers([]), []);

  return (
    <Dialog
      open={isAddEventPostbackModalOpen}
      maxWidth={false}
    >
      <div
        className={classes.dialogWrapper}
      >
        <Box px={3} py={2}>
          <Typography variant="h6">
            {currentEventPostback ? "Edit" : "Add"}
          </Typography>
          <IconButton className={classes.closeButton} onClick={handleOnClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent dividers className={classes.dialogContent}>
          <Autocomplete
            className={classes.fieldSpacing}
            fullWidth
            disableClearable
            classes={{
              inputRoot: classes.input,
            }}
            options={Object.keys(lists.events)}
            value={fields.eventType}
            inputValue={fields.eventType}
            data-testid="eventType"
            onChange={handleChangeEventType}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Event type"
                variant="outlined"
                label="Event type"
                error={!!errorsObject.eventType}
                helperText={errorsObject.eventType && errorsObject.eventType.message}
                InputProps={{
                  ...params.InputProps,
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        className={classes.helperIcon}
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style: zeroSpacing,
                }}
              />
            )}
          />
          <Autocomplete
            className={classes.fieldSpacing}
            fullWidth
            disableClearable
            classes={{
              inputRoot: classes.input,
            }}
            options={lists.events[fields.eventType] || []}
            value={fields.event}
            data-testid="event"
            inputValue={fields.event}
            onChange={(e, item) => setFields({ ...fields, event: item })}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Event"
                variant="outlined"
                label="Event"
                error={!!errorsObject.event}
                helperText={errorsObject.event && errorsObject.event.message}
                InputProps={{
                  ...params.InputProps,
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        className={classes.helperIcon}
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style: zeroSpacing,
                }}
              />
            )}
          />
          <Autocomplete
            className={classes.fieldSpacing}
            fullWidth
            disableClearable
            classes={{
              inputRoot: classes.input,
            }}
            options={offers}
            value={fields.offerId}
            data-testid="offerId"
            onChange={handleSelectOffer}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Cpa offer name"
                variant="outlined"
                label="Cpa offer name"
                error={!!errorsObject.offerId}
                onChange={handleChangeOffers}
                helperText={errorsObject.offerId && errorsObject.offerId.message}
                InputProps={{
                  ...params.InputProps,
                  classes: { adornedStart: classes.input },
                  endAdornment: (
                    <>
                      {isOffersLoading ? (
                        <CircularProgress color="inherit" size={20} disableShrink />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  startAdornment: (
                    <>
                      <InputAdornment
                        className={classes.helperIcon}
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style: zeroSpacing,
                }}
              />
            )}
          />
          <Autocomplete
            className={classes.fieldSpacing}
            fullWidth
            multiple
            classes={{
              inputRoot: classes.input,
            }}
            options={sanitizeCountry(
              lists.countries,
              fields.country,
              countries,
              fields.offerId.id
            )}
            value={fields.country}
            data-testid="country"
            onChange={(e, item) => setFields({ ...fields, country: item })}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Country"
                variant="outlined"
                label="Country"
                error={!!errors.country}
                helperText={errors.country && errors.country.message}
                InputProps={{
                  ...params.InputProps,
                  classes: { adornedStart: classes.input },
                  endAdornment: (
                    <>
                      {isCountriesLoading ? (
                        <CircularProgress color="inherit" size={20} disableShrink />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  startAdornment: (
                    <>
                      <InputAdornment
                        className={classes.helperIcon}
                        position="start"
                      >
                        <Tooltip title="">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style: zeroSpacing,
                }}
              />
            )}
          />
          <TextField
            className={classes.inputField}
            error={!!errorsObject.eventUrl}
            helperText={errorsObject.eventUrl && errorsObject.eventUrl.message}
            fullWidth
            inputProps={{
              style: { background: classes.input },
            }}
            FormHelperTextProps={{
              style: zeroSpacing,
            }}
            variant="outlined"
            label="Event code"
            name="eventUrl"
            data-testid="eventUrl"
            value={fields.eventUrl}
            onChange={handleChangeField}
            placeholder="Event code"
            InputProps={inputPropsTextField}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave} color="primary" data-testid="saveModalButton">
            Save
          </Button>
          <Button autoFocus onClick={handleOnClose} color="primary" data-testid="backModalButton">
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldsForm);
