// @flow
/* eslint-disable max-classes-per-file */
import moment from "moment";
import type { PaymentDetailsPayloadType, PaymentDetailsType } from "../../../components/PaymentDetailsCard";
import type { FieldItem } from "../../../components/CardGridContent/types/CardGridContent.types";

export default class PaymentType {
  paymentDetails: PaymentDetailsType;

  paymentDetailsMapper: FieldItem[];

  _prepareData(data: PaymentDetailsPayloadType) {
    const {
      affId,
      affiliateType,
      paymentMethodId,
      updatedAt,
      beCountries,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      currency: "",
      beAddress: "",
      paymentComment: "",
      paymentMethodCreds: {},
      affId,
      affiliateType,
      beCountry: (beCountries && beCountries.country_name) || "",
      lastUpdateDate: updatedAt,
      paymentMethodId,
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    this.paymentDetailsMapper = [
      {
        label: "message",
        value: details.lastUpdateDate ? `Last update ${moment(details.lastUpdateDate).format("YYYY-MM-DD")}` : " ",
      },
      {
        label: "ID",
        value: details.affId,
        color: comparingDetails && comparingDetails.affId !== details.affId ? "#C80022" : "#000000",
      },
      {
        label: "Beneficiary address",
        value: details.beAddress,
        color: comparingDetails && comparingDetails.beAddress !== details.beAddress ? "#C80022" : "#000000",
      },
      {
        label: "Beneficiary country",
        value: details.beCountry,
        color: comparingDetails && comparingDetails.beCountry !== details.beCountry ? "#C80022" : "#000000",
      },
    ];
  }

  _pushCommonFieldsToCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    this.paymentDetailsMapper.push({
      label: "Affiliate type",
      value: details.affiliateType,
      color: comparingDetails && comparingDetails.affiliateType !== details.affiliateType ? "#C80022" : "#000000",
    },
    {
      label: "Payment currency",
      value: details.currency,
      color: comparingDetails && comparingDetails.currency !== details.currency ? "#C80022" : "#000000",
    },
    {
      label: "Payment comment",
      value: details.paymentComment,
    });
  }

  _getFieldComparingColor(
    key: string,
    details: PaymentDetailsType,
    comparingDetails?: PaymentDetailsType
  ): string {
    return comparingDetails && comparingDetails.paymentMethodCreds[key] !== details.paymentMethodCreds[key] ? "#C80022" : "#000000";
  }

  getPaymentDetailsMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType): FieldItem[] {
    this._prepareCardMapper(details, comparingDetails);
    return this.paymentDetailsMapper;
  }

  getPaymentDetails(data: PaymentDetailsPayloadType): PaymentDetailsType {
    this._prepareData(data);
    return this.paymentDetails;
  }
}

export class WireTransfer extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beAddress,
      bankAccountName,
      bankName,
      bankAddress,
      countries,
      swiftCode,
      accountNumber,
      iban,
      paymentNote,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress,
      paymentMethodCreds: {
        bankAccountName: bankAccountName || "",
        bankName: bankName || "",
        bankAddress: bankAddress || "",
        payoutCountry: (countries && countries.country_name) || "",
        swiftCode: swiftCode || "",
        accountNumber: accountNumber || "",
        iban: iban || "",
        paymentNote: paymentNote || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Bank account name",
        value: details.paymentMethodCreds.bankAccountName,
        color: this._getFieldComparingColor("bankAccountName", details, comparingDetails),
      },
      {
        label: "Bank name",
        value: details.paymentMethodCreds.bankName,
        color: this._getFieldComparingColor("bankName", details, comparingDetails),
      },
      {
        label: "Bank address",
        value: details.paymentMethodCreds.bankAddress,
        color: this._getFieldComparingColor("bankAddress", details, comparingDetails),
      },
      {
        label: "Payout country",
        value: details.paymentMethodCreds.payoutCountry,
        color: this._getFieldComparingColor("payoutCountry", details, comparingDetails),
      },
      {
        label: "S.W.I.F.T./BIC code",
        value: details.paymentMethodCreds.swiftCode,
        color: this._getFieldComparingColor("swiftCode", details, comparingDetails),
      },
      {
        label: "IBAN",
        value: details.paymentMethodCreds.iban,
        color: this._getFieldComparingColor("iban", details, comparingDetails),
      },
      {
        label: "Account number",
        value: details.paymentMethodCreds.accountNumber,
        color: this._getFieldComparingColor("accountNumber", details, comparingDetails),
      },
      {
        label: "Payment note",
        value: details.paymentMethodCreds.paymentNote,
        color: this._getFieldComparingColor("paymentNote", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class Paxum extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      paxumMail,
      beAddress,
      affiliateType,
    }: PaymentDetailsPayloadType = data;

    const country = (beCountries && beCountries.country_name) || "";

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (["adsempiresmartlink", "adsempiredirect", "directdeals", "adveryarbitrage"].includes(affiliateType)) ? beAddress : country,
      paymentMethodCreds: {
        beName,
        paxumMail: paxumMail || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Paxum email",
        value: details.paymentMethodCreds.paxumMail,
        color: this._getFieldComparingColor("paxumMail", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class WebMoneyWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      webmoneyWallet,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        webmoneyWallet: webmoneyWallet || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "WebMoney wallet",
        value: details.paymentMethodCreds.webmoneyWallet,
        color: this._getFieldComparingColor("webmoneyWallet", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class EWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      eWallet,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        eWallet: eWallet || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "ePayments e-Wallet",
        value: details.paymentMethodCreds.eWallet,
        color: this._getFieldComparingColor("eWallet", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class BitcoinWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      bitcoin,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        bitcoin: bitcoin || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Bitcoin wallet",
        value: details.paymentMethodCreds.bitcoin,
        color: this._getFieldComparingColor("bitcoin", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class UsdtWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      usdt,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        usdt: usdt || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "USDT TRC20",
        value: details.paymentMethodCreds.usdt,
        color: this._getFieldComparingColor("usdt", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class QiWi extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      qiwi,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        qiwi: qiwi || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "QIWI",
        value: details.paymentMethodCreds.qiwi,
        color: this._getFieldComparingColor("qiwi", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class YandexMoney extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      yandex,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        yandex: yandex || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Yandex.Money wallet",
        value: details.paymentMethodCreds.yandex,
        color: this._getFieldComparingColor("yandex", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class CapitalistWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      capitalistWallet,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        capitalistWallet: capitalistWallet || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Capitalist wallet",
        value: details.paymentMethodCreds.capitalistWallet,
        color: this._getFieldComparingColor("capitalistWallet", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class PayoneerWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      payoneerWallet,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        payoneerWallet: payoneerWallet || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Payoneer wallet",
        value: details.paymentMethodCreds.payoneerWallet,
        color: this._getFieldComparingColor("payoneerWallet", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class PayPalWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      payPalWallet,
      affiliateType,
      beAddress,
    }: PaymentDetailsPayloadType = data;

    const country = (beCountries && beCountries.country_name) || "";

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (["adsempiresmartlink", "adsempiredirect", "directdeals"].includes(affiliateType)) ? beAddress : country,
      paymentMethodCreds: {
        beName,
        payPalWallet: payPalWallet || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "PayPal wallet",
        value: details.paymentMethodCreds.payPalWallet,
        color: this._getFieldComparingColor("payPalWallet", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class GenomeWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      genome,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        genome: genome || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Genome wallet",
        value: details.paymentMethodCreds.genome,
        color: this._getFieldComparingColor("genome", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}

export class PayseraWallet extends PaymentType {
  _prepareData(data: PaymentDetailsPayloadType) {
    super._prepareData(data);
    const {
      beName,
      beCountries,
      paysera,
    }: PaymentDetailsPayloadType = data;

    this.paymentDetails = {
      ...this.paymentDetails,
      beAddress: (beCountries && beCountries.country_name) || "",
      paymentMethodCreds: {
        beName,
        paysera: paysera || "",
      },
    };
  }

  _prepareCardMapper(details: PaymentDetailsType, comparingDetails?: PaymentDetailsType) {
    super._prepareCardMapper(details, comparingDetails);

    this.paymentDetailsMapper = [
      ...this.paymentDetailsMapper,
      {
        label: "Beneficiary name",
        value: details.paymentMethodCreds.beName,
        color: this._getFieldComparingColor("beName", details, comparingDetails),
      },
      {
        label: "Paysera wallet",
        value: details.paymentMethodCreds.paysera,
        color: this._getFieldComparingColor("paysera", details, comparingDetails),
      },
    ];

    this._pushCommonFieldsToCardMapper(details, comparingDetails);
  }
}
