/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Dialog } from "@mui/material";
import type { Dropdowns } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import { getIsModalTemplateOpen } from "../../selectors/templates";
import TemplatesList from "../../containers/TemplatesList";
import TemplateModal from "../../containers/TemplateModal";
import { userService } from "../../services/user";
import { getCpaOffersDropdownLists } from "../../selectors/dictionaries";
import { fetchSuggestDropdown } from "../../services/templatesApi";
import { getTemplatesConfigSaga, getDefaultTemplateSaga } from "../../actions/templates";
import { CpaFactoryModel } from "../../services/templateModels/cpaOfferModels";

const CpaOffersActionRow: StatelessFunctionalComponent<{}> = () => {
  const isTemplateModalOpen: boolean = useSelector(getIsModalTemplateOpen);
  const dropdownsList: Dropdowns<string> = useSelector(getCpaOffersDropdownLists);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplatesConfigSaga());
    dispatch(getDefaultTemplateSaga());
  }, []);

  return (
    <Box display="flex" justifyContent="flex-start" mb={2}>
      {
        userService.can([{ method: "GET", path: "/api/v1/templates" }]) && (
          <>
            <TemplatesList />
            <Dialog
              fullWidth
              maxWidth="lg"
              open={isTemplateModalOpen}
            >
              <TemplateModal
                isSystemUser={userService.can(["system.template.crud"])}
                userId={userService.getId()}
                fetchSuggestMethod={fetchSuggestDropdown}
                dropdownsList={dropdownsList}
                baseAttribute="id"
                FactoryModel={CpaFactoryModel}
              />
            </Dialog>
          </>
        )}
    </Box>
  );
};

export default CpaOffersActionRow;
