// @flow
import UserApi from "@fas/ui-framework/lib/services/userApi";
import type { PromiseResponse, Response } from "@fas/ui-framework/lib/services/request";
import type { User as UserType } from "@fas/ui-framework/lib/services/user/types/user.types";
import { requestService } from "../axiosRequest";
import { userService } from "../user";
import api from "../api";

class UserApiService extends UserApi {
  allowedTenantIds = [];

  allowedTenantOperationIds = {};

  getUserInfo(url: string): PromiseResponse<mixed, { user: UserType }> {
    return this._request.get(url, { customAttrs: { ignoreAuthorization: true } })
      .then(async (body: Response<mixed, { user: UserType }>): Response<mixed, { user: UserType }> => {
        const { data: { user } }: Response<mixed, { user: UserType }> = body;
        try {
          const {
            allowedTenantOperationIds,
            allowedOperationIds,
            allowedTenantIds,
          } = await api.getUserMe("unknown");
          const {
            permissions = {
              url: [],
              obj: [],
              api: [],
            },
          } = user;

          if (allowedTenantOperationIds) {
            const isHavePermission: boolean = Object.values(allowedTenantOperationIds || {}).flat().includes("getUserMe");
            if (isHavePermission) {
              permissions.obj = permissions.obj.concat("getUserMe");
            }
          }
          else {
            permissions.obj = permissions.obj.concat(allowedOperationIds || []);
          }
          user.permissions = permissions;
          this.allowedTenantIds = [...allowedTenantIds];
          this.allowedTenantOperationIds = allowedTenantOperationIds;
        }
        catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        this._user.setUser(user);
        return body;
      });
  }

  getUser(): UserType | null {
    return this._user?.getUser?.();
  }
}

// eslint-disable-next-line import/prefer-default-export
export const userApiService: UserApi = new UserApiService(userService, requestService);
