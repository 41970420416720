import { getTableFilters } from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import useTenantId from "../useTenantId";
import { useSelector } from "../store";
import { EXT_RATE_AFFILIATES_TABLE_KEY } from "../../../helpers/constants/affiliates";
import api from "../../../services/api";
import useAffiliateId from "../ProfileAffiliate/useAffiliateId";
import type { ExtRateType, FiltersType } from "./types";
import useExtRateQueryKey from "./useExtRateQueryKey";

export function getExtRateId(item?: ExtRateType | null) {
  if (!item) return "";
  return `${item.affiliateId}-${item.offerId}`;
}

export function isEqualExtRate(a?: ExtRateType, b?: ExtRateType) {
  return !!a && !!b && getExtRateId(a) === getExtRateId(b);
}

function useExtRate() {
  const tenantId = useTenantId();
  const affiliateId = useAffiliateId();
  const filters = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, EXT_RATE_AFFILIATES_TABLE_KEY));
  const queryKey = useExtRateQueryKey();
  return useQuery(
    queryKey,
    async () => {
      const queryParams = {
        search: filters.search?.trim() || undefined,
      };

      return api.getAffiliateExtRates(tenantId, affiliateId, {
        ...queryParams,
      });
    }
  );
}

export default useExtRate;
