import { userApiService } from "../../services/userApi";

type Data = {
  id: number;
  name: string;
  allowedTenantIds: string[];
};

export default function useUser(): Data {
  return {
    name: userApiService.getUser()?.name || "",
    id: userApiService.getUser()?.id || 0,
    allowedTenantIds: userApiService.allowedTenantIds || [],
  };
}
