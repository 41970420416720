/* eslint-disable complexity */
import React, { useRef, useEffect } from "react";
import { TextField, InputAdornment, Tooltip, Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Loader } from "@fas/ui-core";
import PaymentInfoTablet from "../PaymentInfoTablet";
import WalletFieldMask from "../WalletFieldMask";

type Props = {
  loading: Boolean,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  setField: (string, string) => any,
  affType: String,
  hasCopyId: boolean,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  paymentInfoItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  inputSelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

const PaymentDetails = ({
  loading,
  title,
  lists,
  errors,
  fields,
  paymentInfo,
  personalInfo,
  setField,
  affType,
  hasCopyId,
}: Props) => {
  const classes = useStyles();

  const bankNameRef = useRef(null);
  const bankAddressRef = useRef(null);
  const swiftCodeRef = useRef(null);
  const ibanRef = useRef(null);
  const vatNumberRef = useRef(null);
  const accountNumberRef = useRef(null);
  const paymentNoteRef = useRef(null);
  const paxumMailRef = useRef(null);
  const webmoneyWalletRef = useRef(null);
  const yandexRef = useRef(null);
  const eWalletRef = useRef(null);
  const bitcoinRef = useRef(null);
  const qiwiRef = useRef(null);
  const capitalistWalletRef = useRef(null);
  const payoneerWalletRef = useRef(null);
  const payPalWalletRef = useRef(null);
  const genomeWalletRef = useRef(null);
  const payseraWalletRef = useRef(null);
  const usdtRef = useRef(null);

  const {
    paymentMethodId,
    bankName,
    bankAddress,
    payoutCountry,
    swiftCode,
    iban,
    vatNumber,
    accountNumber,
    paymentNote,
    paxumMail,
    webmoneyWallet,
    yandex,
    eWallet,
    bitcoin,
    qiwi,
    capitalistWallet,
    payoneerWallet,
    payPalWallet,
    genome,
    paysera,
    usdt,    
  } = paymentInfo;

  const { declineReasonId } = personalInfo;

  const {
    countries = [],
  } = lists;

  const isShowVatNumber = ["adromeda", "adsempiresmartlink", "adsempiredirect", "adveryarbitrage", "directdeals"].includes(affType);

  const handleChangeField = (e) => {
    const { value, name } = e.target;
    setField(name, value);
  };
  useEffect(() => {
    if (bankNameRef.current) {
      bankNameRef.current.value = bankName;
    }
    if (bankAddressRef.current) {
      bankAddressRef.current.value = bankAddress;
    }
    if (swiftCodeRef.current) {
      swiftCodeRef.current.value = swiftCode;
    }
    if (ibanRef.current) {
      ibanRef.current.value = iban;
    }
    if (vatNumberRef.current) {
      vatNumberRef.current.value = vatNumber;
    }
    if (accountNumberRef.current) {
      accountNumberRef.current.value = accountNumber;
    }
    if (paymentNoteRef.current) {
      paymentNoteRef.current.value = paymentNote;
    }
    if (paxumMailRef.current) {
      paxumMailRef.current.value = paxumMail;
    }
    if (webmoneyWalletRef.current) {
      webmoneyWalletRef.current.value = webmoneyWallet;
    }
    if (yandexRef.current) {
      yandexRef.current.value = yandex;
    }
    if (paxumMailRef.current) {
      paxumMailRef.current.value = paxumMail;
    }
    if (bitcoinRef.current) {
      bitcoinRef.current.value = bitcoin;
    }
    if (eWalletRef.current) {
      eWalletRef.current.value = eWallet;
    }
    if (qiwiRef.current) {
      qiwiRef.current.value = qiwi;
    }
    if (capitalistWalletRef.current) {
      capitalistWalletRef.current.value = capitalistWallet;
    }
    if (payoneerWalletRef.current) {
      payoneerWalletRef.current.value = payoneerWallet;
    }
    if (payPalWalletRef.current) {
      payPalWalletRef.current.value = payPalWallet;
    }
    if (genomeWalletRef.current) {
      genomeWalletRef.current.value = genome;
    }
    if (payseraWalletRef.current) {
      payseraWalletRef.current.value = paysera;
    }
    if (usdtRef.current) {
      usdtRef.current.value = usdt;
    }
  });

  return (
    <Loader loading={loading}>
      <PaymentInfoTablet
        title={title}
        errors={errors}
        fields={fields}
      >
        {
          // declineReason = "fraud (autodecline)"
          "id" in declineReasonId && declineReasonId.id === 16 && (
          <Alert severity="warning" data-testid="aff-decline-text" sx={{marginBottom: 2}}>
            <pre style={{ textAlign: "initial", whiteSpace: "break-spaces" }}>This payment has already been registered and is in blacklist</pre>
          </Alert>
        )}
        {paymentMethodId && paymentMethodId.id === 1 ? (
          <>
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="bankName"
              data-testid="bankName"
              variant="outlined"
              label="Bank name"
              placeholder="Bank name"
              error={!!errors.bankName}
              helperText={errors.bankName && errors.bankName}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={bankNameRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="bankAddress"
              data-testid="bankAddress"
              variant="outlined"
              label="Bank address"
              placeholder="Bank address"
              error={!!errors.bankAddress}
              helperText={errors.bankAddress && errors.bankAddress}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={bankAddressRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <Autocomplete
              disabled={hasCopyId}
              className={classes.inputField}
              fullWidth
              classes={{
                inputRoot: classes.input,
              }}
              options={countries}
              value={payoutCountry}
              data-testid="payoutCountry"
              onChange={(e, item) => setField("payoutCountry", item)}
              getOptionLabel={(option) => option.country_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose country"
                  variant="outlined"
                  label="Payout country"
                  error={!!errors.payoutCountry}
                  helperText={errors.payoutCountry && errors.payoutCountry}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: { adornedStart: classes.input },
                    startAdornment: (
                      <>
                        <InputAdornment
                          className={classes.helperIcon}
                          position="start"
                        >
                          <Tooltip title="Some text">
                            <HelpOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="swiftCode"
              data-testid="swiftCode"
              variant="outlined"
              label="S.W.I.F.T./BIC code"
              placeholder="S.W.I.F.T./BIC code"
              error={!!errors.swiftCode}
              helperText={errors.swiftCode && errors.swiftCode}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={swiftCodeRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="iban"
              data-testid="iban"
              variant="outlined"
              label="Iban"
              placeholder="Iban"
              error={!!errors.iban}
              helperText={errors.iban && errors.iban}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={ibanRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="vatNumber"
              data-testid="vatNumber"
              variant="outlined"
              label="VAT number"
              placeholder="VAT number"
              error={!!errors.vatNumber}
              helperText={errors.vatNumber && errors.vatNumber}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={vatNumberRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="accountNumber"
              data-testid="accountNumber"
              variant="outlined"
              label="Account number"
              placeholder="Account number"
              error={!!errors.accountNumber}
              helperText={errors.accountNumber && errors.accountNumber}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={accountNumberRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="paymentNote"
              data-testid="paymentNote"
              variant="outlined"
              label="Payment note"
              placeholder="Payment note"
              error={!!errors.paymentNote}
              helperText={errors.paymentNote && errors.paymentNote}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={paymentNoteRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </>
        ) : (
          <>
            {paymentMethodId.id === 2 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="paxumMail"
                data-testid="paxumMail"
                variant="outlined"
                label="Paxum email"
                placeholder="e.g. example@domain.com"
                error={!!errors.paxumMail}
                helperText={errors.paxumMail && errors.paxumMail}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={paxumMailRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 3 && (
              <WalletFieldMask
                disabled={hasCopyId}
                label="WebMoney wallet"
                name="webmoneyWallet"
                value={webmoneyWallet}
                error={errors.webmoneyWallet}
                setField={setField}
                placeholder="e.g. Z345345345341"
              />
            )}
            {paymentMethodId.id === 5 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="bitcoin"
                data-testid="bitcoin"
                variant="outlined"
                label="Bitcoin wallet"
                placeholder="e.g. 14qViLJfdGaP4EeHnDyJbEGQysnCpwk3gd"
                error={!!errors.bitcoin}
                helperText={errors.bitcoin && errors.bitcoin}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={bitcoinRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 6 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="qiwi"
                data-testid="qiwi"
                variant="outlined"
                label="QIWI"
                placeholder="e.g. 81545678910 or +81545678910"
                error={!!errors.qiwi}
                helperText={errors.qiwi && errors.qiwi}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={qiwiRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 7 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="yandex"
                data-testid="yandex"
                variant="outlined"
                label="Yandex.Money wallet"
                placeholder="e.g. 12345678910"
                error={!!errors.yandex}
                helperText={errors.yandex && errors.yandex}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={yandexRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 8 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="capitalistWallet"
                data-testid="capitalistWallet"
                variant="outlined"
                label="Capitalist wallet"
                placeholder="e.g. U11276582"
                error={!!errors.capitalistWallet}
                helperText={errors.capitalistWallet && errors.capitalistWallet}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={capitalistWalletRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 9 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="payoneerWallet"
                data-testid="payoneerWallet"
                variant="outlined"
                label="Payoneer wallet"
                placeholder="e.g. example@domain.com"
                error={!!errors.payoneerWallet}
                helperText={errors.payoneerWallet && errors.payoneerWallet}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={payoneerWalletRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 10 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="payPalWallet"
                data-testid="payPalWallet"
                variant="outlined"
                label="PayPal wallet"
                placeholder="e.g. example@domain.com"
                error={!!errors.payPalWallet}
                helperText={errors.payPalWallet && errors.payPalWallet}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={payPalWalletRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 11 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="genome"
                data-testid="genome"
                variant="outlined"
                label="Genome wallet"
                placeholder="e.g. 0000000000000000"
                error={!!errors.genome}
                helperText={errors.genome && errors.genome}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={genomeWalletRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 12 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="paysera"
                data-testid="paysera"
                variant="outlined"
                label="Paysera wallet"
                placeholder="e.g. 0000000000000000"
                error={!!errors.paysera}
                helperText={errors.paysera && errors.paysera}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={payseraWalletRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {paymentMethodId.id === 13 && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="usdt"
                data-testid="usdt"
                variant="outlined"
                label="USDT TRC20"
                placeholder="e.g. 14qViLJfdGaP4EeHnDyJbEGQysnCpwk3gd"
                error={!!errors.usdt}
                helperText={errors.usdt && errors.usdt}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={usdtRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
            {isShowVatNumber && (
              <TextField
                disabled={hasCopyId}
                fullWidth
                className={classes.inputField}
                name="vatNumber"
                data-testid="vatNumber"
                variant="outlined"
                label="VAT number"
                placeholder="VAT number"
                error={!!errors.vatNumber}
                helperText={errors.vatNumber && errors.vatNumber}
                inputProps={{
                  style: { background: classes.input },
                }}
                inputRef={vatNumberRef}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                onBlur={handleChangeField}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          </>
        )}
      </PaymentInfoTablet>
    </Loader>
  );
};

export default PaymentDetails;
