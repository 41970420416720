// @flow
import React from "react";
import {
  Error,
} from "@fas/ui-core";

const ErrorPage = () => (
  <Error />
);

export default ErrorPage;
