// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import moment from "moment";
import environment from "environment";
import {
  Box, FormControlLabel, Link, Switch, Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  SimpleSelect,
} from "@fas/ui-core";
import {
  generateLinkToBasic,
} from "@fas/ui-core/lib/utils";
import { useTable } from "@fas/ui-framework/lib/services/table";
import MultiselectWithSuggest from "@fas/ui-core/lib/SearchComponentsV2/MultiselectWithSuggest";
import { useLocation } from "react-router-dom";
import { getDropdown } from "@fas/ui-framework/lib/redux/selectors/dropdowns";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { User } from "@fas/ui-framework/lib/services/user";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { userService } from "../../services/user";
import { fetchCpaOffersList } from "../../services/cpaOffersApi/cpaOffers";
import { getCpaOfferCampaignsSaga } from "../../actions/cpaOfferCampaigns";
import { CPA_OFFER_CAMPAIGNS_TABLE } from "../../helpers/constants/cpaoffer";
import type { CpaOfferCampaignData } from "../../services/cpaOffersApi/cpaOffers";
import type { State } from "../../pages/CpaOfferCampaigns/store";
import TableActionsRow from "./TableActionsRow";

export type Props = {}

const CpaOfferCampaigns: StatelessFunctionalComponent<Props> = ({
}: Props) => {
  const search: URLSearchParams = new URLSearchParams(useLocation().search);
  const cpaOfferId: string | null = search.get("id");
  const additionalParameter: string = cpaOfferId || "";
  const user: User | null = userService.getUser();

  const splitOptions: Array<{ label: string, value: string }> = useSelector((state: State): * => getDropdown(state, "split"));
  const campaignStatusOptions: Array<{ label: string, value: string }> = useSelector((state: State): * => getDropdown(state, "campaignStatus"));
  const offerStatusOptions: Array<{ label: string, value: string }> = useSelector((state: State): * => getDropdown(state, "offerStatus"));
  const offerInCampaignStatusOptions: Array<{ label: string, value: string }> = useSelector((state: State): * => getDropdown(state, "offerInCampaignStatus"));

  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(CPA_OFFER_CAMPAIGNS_TABLE);

  const handleGetCpaOfferCampaigns: () => * = () => dispatch(getCpaOfferCampaignsSaga());
  const handleGetCpaOffersList:
    (string, string) => * = (name, filterValue) => fetchCpaOffersList(name, additionalParameter, filterValue);
  const handleChangeOnlyMy: (SyntheticInputEvent<HTMLInputElement>, boolean) => void = (e, val) => {
    // eslint-disable-next-line no-unused-vars
    const { createdBy, ...otherFilters }: Filters = tableProps.filters;
    if (user && val) {
      // $FlowFixMe
      otherFilters.createdBy = [{ label: user.name, value: user.name }];
    }
    tableProps.onChangeFilters(
      otherFilters
    );
  };

  const handleChangeFilters = (filters: Filters) => {
    const formatedFilters: Filters = {};
    for (const key: string in filters) {
      if (filters[key] && filters[key].length) {
        formatedFilters[key] = filters[key];
      }
    }
    tableProps.onChangeFilters(formatedFilters);
  };

  const columns: Array<Column<CpaOfferCampaignData>> = [
    {
      label: "Offer ID",
      field: "offerId",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe MTU-94094
          fetchMethod={handleGetCpaOffersList}
          name="offerId"
        />
      ),
      render: ({ offerId }: CpaOfferCampaignData): Node => (
        <Link
          target="_blank"
          href={` ${environment.appLinks.cpa}cpaOffers/${offerId}`}
          underline="hover"
        >
          {offerId}
        </Link>
      ),
    },
    {
      label: "Offer name",
      field: "offerName",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe MTU-94094
          fetchMethod={handleGetCpaOffersList}
          name="offerName"
        />
      ),
    },
    {
      label: "Basic ID",
      field: "campaignId",
      searchable: true,
      sortable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe MTU-94094
          fetchMethod={handleGetCpaOffersList}
          name="campaignId"
        />
      ),
      render: ({ campaignId }: CpaOfferCampaignData): Node => (
        <Link
          target="_blank"
          href={generateLinkToBasic(environment.appLinks.dmp, campaignId)}
          underline="hover"
        >
          {campaignId}
        </Link>
      ),
    },
    {
      label: "Basic name",
      field: "campaignName",
      searchable: true,
      sortable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe MTU-94094
          fetchMethod={handleGetCpaOffersList}
          name="campaignName"
        />
      ),
    },
    {
      label: "Split",
      field: "split",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe MTU-94094
          values={splitOptions}
          name="split"
        />
      ),
    },
    {
      label: "Funnel name",
      // $FlowFixMe
      field: "funnelName",
      searchable: true,
      sortable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe MTU-94094
          fetchMethod={handleGetCpaOffersList}
          name="funnelName"
        />
      ),
      render: ({ campaignId, split, funnel: { funnelIndex, funnelType, funnelName } }: CpaOfferCampaignData): Node => (
        <Link
          target="_blank"
          href={generateLinkToBasic(environment.appLinks.dmp, campaignId, split, funnelIndex, funnelType)}
          underline="hover"
        >
          {funnelName}
        </Link>
      ),
    },
    {
      label: "Created at",
      field: "created",
      sortable: true,
      render: ({ created }: CpaOfferCampaignData): Node => (<span>{moment(created).format("MM.DD.YY hh:mm:ss")}</span>),
    },
    {
      label: "Basic status",
      field: "campaignStatus",
      render: ({ campaignStatus }: CpaOfferCampaignData): Node => (
        <Typography sx={{ color: campaignStatus ? "green" : "red" }}>{campaignStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe MTU-94094
          values={campaignStatusOptions}
          name="campaignStatus"
        />
      ),
    },
    {
      label: "Offer status",
      field: "offerStatus",
      render: ({ offerStatus }: CpaOfferCampaignData): Node => (
        <Typography sx={{ color: offerStatus ? "green" : "red" }}>{offerStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe MTU-94094
          values={offerStatusOptions}
          name="offerStatus"
        />
      ),
    },
    {
      label: "Offer status in basic",
      field: "offerInCampaignStatus",
      render: ({ offerInCampaignStatus }: CpaOfferCampaignData): Node => (
        <Typography sx={{ color: offerInCampaignStatus ? "green" : "red" }}>{offerInCampaignStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe MTU-94094
          values={offerInCampaignStatusOptions}
          name="offerInCampaignStatus"
        />
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Box ml={2} display="flex" alignItems="center">
          <FormControlLabel
            control={(
              <Switch
                checked={!!tableProps.filters.createdBy}
                color="secondary"
                onChange={handleChangeOnlyMy}
                data-testid="search-createdBy"
              />
            )}
            label="Show only my"
          />
        </Box>
      </Box>
      <Table
        {...tableProps}
        onChangeFilters={handleChangeFilters}
        title="CPA offer campaigns"
        allSelectAvailable={() => true}
        rowSelectAvailable={() => true}
        columns={columns}
        onLoad={handleGetCpaOfferCampaigns}
        Actions={<TableActionsRow />}
      />
    </Box>
  );
};

export default CpaOfferCampaigns;
