import React from "react";
import { Box } from "@mui/material";

export default function DevTools() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((prev) => !prev);
  }, []);

  // eslint-disable-next-line import/no-extraneous-dependencies
  const ReactQueryDevtools = React.lazy(() => import("@tanstack/react-query-devtools").then((d) => ({
    default: d.ReactQueryDevtoolsPanel,
  })));

  return (
    <>
      {showDevtools && (
        <React.Suspense fallback={null}>
          <Box zIndex={1} position="sticky" mt={5} bottom={8} left={0} right={0} textAlign="left">
            <ReactQueryDevtools
              setIsOpen={setShowDevtools}
              onDragStart={() => null}
            />
          </Box>
        </React.Suspense>
      )}
    </>
  );
}
