// @flow
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
} from "@mui/material";
import { Table } from "@fas/ui-core";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import {
  getAffiliateLinksSaga, openAffiliateLinkModal,
} from "../../../../actions/affiliateLinks";
import FieldsForm from "./components/FieldsForm";
import { baseColumns, columnsWithActions } from "./utils";
import type { GetAffiliateLinksSaga, OpenAffiliateLinkModal } from "../../../../actions/affiliateLinks";
import { AFFILIATE_LINKS_TABLE } from "../../../../helpers/constants/affiliateLinks";

const AffiliateLinks = () => {
  const dispatch = useDispatch();
  const tableProps: TableProps = useTable(AFFILIATE_LINKS_TABLE);

  const onOpenAffiliateModal: () => OpenAffiliateLinkModal = () => (
    dispatch(openAffiliateLinkModal()));
  const onGetAffiliateLinksSaga: () => GetAffiliateLinksSaga = () => (
    dispatch(getAffiliateLinksSaga()));

  const manageAffiliate = useSelector((state) => state.manageAffiliate);
  const {
    type,
  } = manageAffiliate.get("personalInfo").toJS();

  const departments = ["profitsocial", "adsempiresmartlink"];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      alignSelf="stretch"
      m={2}
      mb={3}
    >
      <Table
        {...tableProps}
        title="Affiliate links"
        rowSelectAvailable={(): boolean => false}
        allSelectAvailable={(): boolean => false}
        columns={!departments.includes(type) ? baseColumns : columnsWithActions}
        onLoad={onGetAffiliateLinksSaga}
        onCreate={!departments.includes(type) ? false : onOpenAffiliateModal}
      />
      <FieldsForm />
    </Box>
  );
};

export default AffiliateLinks;
