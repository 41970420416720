// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import PrivateRoute from "../../components/App/PrivateRoute";
import CrmMailsList from "../../containers/CrmMailsList";
import CrmMailsForm from "../../containers/CrmMailsForm";
import configureStore from "./store";

function CrmMails() {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="CRM - Mails">
      <Provider store={store}>
        <NotificationsContainer />
        <PrivateRoute
          permissions={[{ path: "/api/v1/crm/mail/filter", method: "POST" }]}
          component={CrmMailsList}
        />
        <PrivateRoute
          permissions={[{ path: "/api/v1/crm/mail", method: "POST" }]}
          component={CrmMailsForm}
        />
      </Provider>
    </DocumentTitle>
  );
}

export default CrmMails;
