// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setFormData, type GetFormDataSagaType } from "@fas/ui-framework/lib/redux/actions/form";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import setLoading from "../../actions/loading/actions";
import { apiMapGetFormData } from "../../services/form";

export function* makeFetch(action: GetFormDataSagaType): Saga<void> {
  try {
    yield put(setLoading("GET_FORM_DATA_LOADING", true));
    const data = yield call(apiMapGetFormData[action.key], action.id);
    yield put(setFormData(action.key, data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setFormData(action.key, { isError: true }));
  }
  finally {
    yield put(setLoading("GET_FORM_DATA_LOADING", false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_FORM_DATA_SAGA, (makeFetch: Function));
}
