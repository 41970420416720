export default (baseUrl: string) => ({
  baseUrl,
  appLinks: {
    dmp: "https://rel-admin-dmp.insigit.com/",
    jus: "https://jus.rel.insigit.com/",
    cpa: "https://cpa-admin.rel.insigit.com/",
    webPush: "https://webpush.rel.noclef.com/",
    adsBox: "https://adsbox.rel.insigit.com/",
    reports: "https://reports.rel.insigit.com/managersReport",
    crm: "https://crm.rel.insigit.com/",
    cronusAdmin: "https://cronus-admin.rel.insigit.com/",
  },
  links: {
    accountList: "/accounts/list",
    accountView: "/funnelTemplates/view",
    documentList: "/pages/list",
    redirectList: "/redirects/list",
  },
  endpoints: {
    get: {
      login: `${baseUrl}/api/login`,
      loginUrl: `${baseUrl}/login`,
      logout: `${baseUrl}/logout`,
      getAffiliates: `${baseUrl}/api/v1/cpa/affiliate`,
      getDasboardGET: `${baseUrl}/api/v1/cpa/dashboard`,
      personalInfoLists: `${baseUrl}/api/v1/cpa/affiliate/lists`,
      personalInfoListsById: `${baseUrl}/api/v1/cpa/affiliate/lists/{affiliateId}`,
      getAffiliate: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}`,
      getAffiliatePayments: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/{name}`,
      getAffiliatePaymentReportPersonalInfo: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/personalInfo`,
      getAffiliatePaymentReportPaymentStats: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/paymentStats`,
      getAffiliatePaymentReportPaymentDate: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/paymentDate`,
      getAffiliatePaymentReportSimilarAffiliates: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/similarAffiliates`,
      getAffiliatePaymentReportPayoutHistory: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentReport/payoutHistory`,
      getOfferList: `${baseUrl}/api/v1/offers`,
      getOffersLists: `${baseUrl}/api/v1/offer/list`,
      generateAffiliatesXlsx: `${baseUrl}/api/v1/cpa/affiliate/generate/xlsx`,
      getPostbackInfo: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks`,
      getPostbackEvents: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks/events`,
      getListsPostback: `${baseUrl}/api/v1/cpa/affiliate/postbackInfo/lists`,
      searchOffers: `${baseUrl}/api/v1/cpa/affiliate/searchOffers`,
      offerOptions: `${baseUrl}/cpa/offers/getOptions`,
      getOffer: `${baseUrl}/api/v1/cpa/offer/{id}/form`,
      getApiIpList: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/api`,
      generateApiKey: `${baseUrl}/user/apiKey/generateApiKey64`,
      getAffiliateLinks: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/smartLinks`,
      getOfferCountries: `${baseUrl}/cpa/subrates/fetchCountries`,
      getManageAffiliateTemplates: `${baseUrl}/api/v1/template/cpaAffiliate/lists`,
      getOffersSubscriptions: `${baseUrl}/api/v1/subscriptions`,
      getTopOffersDropdownLists: `${baseUrl}/api/v1/topOffers/lists`,
      getPayoutTermsDictionary: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown`,
      getPayoutCompanies: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown?type=payoutCompanies`,
      getCurrencies: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown?type=currency`,
      getPaymentMethods: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown?type=paymentMethod`,
      getAffiliateTypes: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown?type=affiliateTypes`,
      getPaymentsTypes: `${baseUrl}/api/v1/dictionary/payoutTerms/dropdown?type=paymentTypes`,
      getPayoutTermsById: `${baseUrl}/api/v1/payoutTerms/{id}`,
      getTopOffersById: `${baseUrl}/api/v1/topOffers/{id}`,
      searchTopOffers: `${baseUrl}/api/v1/topOffers/search`,
      getTopOffersLogo: `${baseUrl}/api/v1/topOffers/{id}/logo`,
      getSubratesDictionary: `${baseUrl}/api/v1/dictionary/subrate/lists`,
      getSubratesDropdowns: `${baseUrl}/api/v1/dictionary/subrate/dropdown`,
      getSubratesTemplates: `${baseUrl}/api/v1/template/subrate/lists`,
      getSubrateById: `${baseUrl}/api/v1/dictionary/subrate/{id}`,
      getCpaAffiliateDropdowns: `${baseUrl}/api/v1/dictionary/cpaAffiliate/dropdown`,
      getManagersListByMarketingGroup: `${baseUrl}/api/v1/dictionary/cpaAffiliate/dropdown`,
      getSmuMarketingCompaniesList: `${baseUrl}/api/v1/bank/departments`,
      getSmuMarketingCompanyById: `${baseUrl}/api/v1/bank/departments/{id}`,
      getSmuMarketingDepartments: `${baseUrl}/api/v1/bank/dropdown`,
      getSmuDropdown: `${baseUrl}/api/v1/bank/dropdown`,
      getSmuBrandsList: `${baseUrl}/api/v1/bank/brands`,
      getSmuBrandById: `${baseUrl}/api/v1/bank/brands/{id}`,
      getSmuBrandImage: `${baseUrl}/api/v1/bank/brands/image`,
      getProductCompanyById: `${baseUrl}/api/v1/bank/companies/{id}`,
      getPotentialPartnerById: `${baseUrl}/api/v1/potentialPartner/{id}`,
      getAdvertiserInvoicesById: `${baseUrl}/api/v1/cpa/advertiser/invoices/{id}`,
      getAdvertiserInvoicesFileById: `${baseUrl}/api/v1/cpa/advertiser/invoices/export`,
      getAdvertiserInvoicesList: `${baseUrl}/api/v1/cpa/advertiser/invoices`,
      getPotentialPartnerList: `${baseUrl}/api/v1/potentialPartner`,
      getPotentialPartnerDropdown: `${baseUrl}/api/v1/potentialPartner/dropdown`,
      getSuggestList: `${baseUrl}/api/v1/dictionary/suggest/{dictionaryName}`,
      getTemplates: `${baseUrl}/api/v1/templates/{dataSourceName}`,
      getTemplatesConfig: `${baseUrl}/api/v1/attributes/{dataSourceName}`,
      getDefaultTemplate: `${baseUrl}/api/v1/templates/default/{dataSourceName}`,
      getDropdownStatic: `${baseUrl}/api/v1/dictionary/{dictionaryName}/static`,
      getDropdownList: `${baseUrl}/api/v1/dictionary/{dictionary}/dropdown`,
      generatedUtmCampaign: `${baseUrl}/api/v1/generatedUtmCampaign`,
      getPaymentInfoById: `${baseUrl}/api/v1/cpa/affiliate/paymentInfo/currentId/{affiliateId}/copyId/{parentAffiliateId}`,
      getAffiliateSmartOffersList: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/smartOffers`,
      getCpaCampaignsOptions: `${baseUrl}/api/v1/dictionary/cpaOffersCampaign/dropdown`,
      getCrmMailById: `${baseUrl}/api/v1/crm/mail/{id}`,
      getCrmTemplateById: `${baseUrl}/api/v1/crm/mailTemplate/{id}`,
    },
    post: {
      saveSubrate: `${baseUrl}/api/v1/dictionary/subrate`,
      saveTopOffers: `${baseUrl}/api/v1/topOffers`,
      getTopOffersList: `${baseUrl}/api/v1/topOffers/filters`,
      getAffiliates: `${baseUrl}/api/v1/template/cpaAffiliate/filter`,
      restoreOffer: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/restore/{subscriptionId}`,
      editPaymentInfo: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/paymentInfo`,
      savePostbackInfo: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks/info`,
      savePostbackEvent: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks/events`,
      savePostback: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks`,
      postbackAction: `${baseUrl}/api/v1/cpa/affiliate/postbackAction`,
      getDasboardPOST: `${baseUrl}/api/v1/cpa/dashboard/filters`,
      addAffiliate: `${baseUrl}/api/v1/cpa/affiliate`,
      updateAffiliate: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}`,
      applyActivesAffiliate: `${baseUrl}/api/v1/cpa/affiliate/bulkActivate`,
      generateLoginLink: `${baseUrl}/api/v1/cpa/affiliate/generateLoginLink`,
      saveCpaOffer: `${baseUrl}/api/v1/cpa/offer`,
      updateCpaOffer: `${baseUrl}/api/v1/cpa/offer/{id}`,
      saveAffiliateLinks: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/smartLinks`,
      resetCap: `${baseUrl}/api/v1/cpa/offer/resetcounter`,
      cloneOffer: `${baseUrl}/cpa/offer/clone`,
      saveAffiliateTemplate: `${baseUrl}/api/v1/template/cpaAffiliate`,
      saveSubscription: `${baseUrl}/api/v1/subscriptions`,
      applyActivesOffer: `${baseUrl}/api/v1/cpa/offer/bulkActivate`,
      changeIsValid: `${baseUrl}/api/v1/cpa/offer/changeIsValid`,
      getSubratesList: `${baseUrl}/api/v1/template/subrate/filter`,
      saveTemplateSubrates: `${baseUrl}/api/v1/template/subrate`,
      checkValidationStatus: `${baseUrl}/api/v1/cpa/offer/checkValidationStatus`,
      savePayoutTerm: `${baseUrl}/api/v1/payoutTerms`,
      getPayoutTermsList: `${baseUrl}/api/v1/payoutTerms/filter`,
      downloadInvoice: `${baseUrl}/api/v1/cpa/invoices/archive`,
      exportCpaOffers: `${baseUrl}/api/v1/cpa/exportOffers`,
      saveProductCompany: `${baseUrl}/api/v1/bank/companies`,
      getProductCompanyList: `${baseUrl}/api/v1/bank/companies`,
      createSmuBrand: `${baseUrl}/api/v1/bank/brands`,
      saveApiIp: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/api`,
      createPotentialPartner: `${baseUrl}/api/v1/potentialPartner`,
      changeTemplateStatus: `${baseUrl}/api/v1/templates/{dataSourceName}/{id}/{relationType}`,
      saveTemplate: `${baseUrl}/api/v1/templates/{dataSourceName}`,
      createAdvertiserInvoices: `${baseUrl}/api/v1/cpa/advertiser/invoices`,
      getCpaOffersData: `${baseUrl}/api/v1/template/cpaOffer/filter`,
      getTemplatesFilterData: `${baseUrl}/api/v1/templates/{dataSourceName}/filter`,
      getTableFilterData: `${baseUrl}/api/v1/template/{dataSourceName}/filter`,
      deactivateAffiliateSmartOffers: `${baseUrl}/api/v1/cpa/affiliate/smartOffers/deactivate`,
      getCpaOfferCampaigns: `${baseUrl}/api/v1/template/cpaOffersCampaign/filter`,
      changeCrmMailsStatus: `${baseUrl}/api/v1/crm/mail/changeStatus`,
      sendCrmMails: `${baseUrl}/api/v1/crm/mail/sendMail`,
      createCrmMail: `${baseUrl}/api/v1/crm/mail`,
      createCrmTemplate: `${baseUrl}/api/v1/crm/mailTemplate`,
      changeAffiliateStatus: `${baseUrl}/api/v1/cpa/affiliate/changeStatus`,
      changeAffiliatePaymentVerify: `${baseUrl}/api/v1/cpa/verifyPaymentInfo`,
      getMailTemplatesList: `${baseUrl}/api/v1/template/mailTemplate/filter`, 
    },
    delete: {
      removeAffiliateLink: `${baseUrl}/api/v1/cpa/affiliate/smartLinks/{smartlinkId}`,
      removePotentialPartner: `${baseUrl}/api/v1/potentialPartner`,
      deletePostbackEvent: `${baseUrl}/api/v1/cpa/affiliate/{affiliateId}/postbacks/events/{postbackId}`,
      deleteSubrate: `${baseUrl}/api/v1/dictionary/subrate`,
      deleteTemplate: `${baseUrl}/api/v1/templates/{dataSourceName}/{id}`,
      deleteCrmTemplate: `${baseUrl}/api/v1/crm/mailTemplate/{id}`,
    },
    put: {
      bulkUpdateSubrates: `${baseUrl}/api/v1/dictionary/subrate/bulkUpdate`,
      bulkCpaOffersAuthorsChange: `${baseUrl}/api/v1/dictionary/cpaOffer/bulkUpdate`,
      bulkCpaAffiliatesAuthorsChange: `${baseUrl}/api/v1/dictionary/cpaAffiliate/bulkUpdate`,
      bulkProductCompanyChange: `${baseUrl}/api/v1/bank/companies/activate`,
      changePayoutTermsActive: `${baseUrl}/api/v1/payoutTerms/deactivate`,
      updateSmuMarketingCompany: `${baseUrl}/api/v1/bank/departments/{id}`,
      updateSmuBrand: `${baseUrl}/api/v1/bank/brands/{id}`,
      updateSmuBrandImage: `${baseUrl}/api/v1/bank/brands/image/{id}`, // TODO: fix route in MTU-57439
      bulkSmuBrandsActiveChange: `${baseUrl}/api/v1/bank/brands/activate`,
      updatePotentialPartner: `${baseUrl}/api/v1/potentialPartner/{id}`,
      bulkPotentialPartner: `${baseUrl}/api/v1/potentialPartner/status`,
      updateAdvertiserInvoices: `${baseUrl}/api/v1/cpa/advertiser/invoices/{id}`,
      updateSubrate: `${baseUrl}/api/v1/dictionary/subrate/{id}`,
      updateProductCompany: `${baseUrl}/api/v1/bank/companies/{id}`,
      updateTemplate: `${baseUrl}/api/v1/templates/{dataSourceName}/{id}`,
      updateCrmMail: `${baseUrl}/api/v1/crm/mail/{id}`,
      updateCrmTemplate: `${baseUrl}/api/v1/crm/mailTemplate/{id}`,
    }
  },
});
