import type React from "react";
import {
  Box, Card, CardContent, Typography,
} from "@mui/material";
import DocumentTitle from "react-document-title";
import type { GroupFeature } from "../types";
import filterByOperation from "../filterByOperation";

const Dashboard: React.FC<{slots: GroupFeature[] }> = ({ slots }) => {
  const availableFeatures = slots.filter(filterByOperation);
  return (
    <DocumentTitle title="CPA - Affiliate dashboard">
      <Box
        m="auto"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-evenly"
        maxWidth="lg"
      >
        {availableFeatures
          .map(({ label, url, CardComponent }) => {
            if (CardComponent) {
              return (
                <Box m={2} key={url}>
                  <Card sx={{ minWidth: 200, minHeight: 200 }} data-testid={label}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {label}
                      </Typography>
                      <CardComponent />
                    </CardContent>
                  </Card>
                </Box>
              );
            }
            return null;
          })}
      </Box>
    </DocumentTitle>
  );
};
export default Dashboard;
