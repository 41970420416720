import type React from "react";
import {
  Navigate, Outlet, Route, Routes,
} from "react-router-dom";
import { AccessDenied, Error } from "@fas/ui-core";
import type { Feature } from "../Affiliates/types";
import filterByOperation from "../Affiliates/filterByOperation";
import clearPath from "../Affiliates/utils/clearPath";

type RouterProps = {
  slots: Feature[],
  Layout?: React.FC<{
    tabs: Pick<Feature, "url" | "label">[],
  }>,
  Index?: React.ReactNode,
  NotFound?: React.FC,
}
function DefaultLayout() {
  return <Outlet />;
}
const RouterSlots: React.FC<RouterProps> = ({
  slots,
  Layout = DefaultLayout,
  Index,
  NotFound = Error,
}) => {
  if (slots.length === 0) return null;
  return (
    <Routes>
      <Route element={<Layout tabs={slots.filter(filterByOperation)} />}>

        <Route index element={Index || <RedirectToFirstAvailableSlot slots={slots} />} />

        {slots.map((feature) => (feature.url ? (
          <Route
            key={feature.url}
            path={feature.url}
            element={<ProtectedRoute feature={feature} />}
          />
        ) : null))}

        <Route path="*" element={<NotFound />} />

      </Route>
    </Routes>
  );
};

function RedirectToFirstAvailableSlot({ slots }: { slots: Feature[] }) {
  const availableFeatures = slots
    .filter(({ url, operationIds }) => url && filterByOperation({ operationIds }));

  const firstUrl = clearPath(availableFeatures[0]?.url || "");

  return (firstUrl ? <Navigate replace to={firstUrl} /> : <AccessDenied />);
}

function ProtectedRoute({ feature }: { feature: Feature }) {
  return filterByOperation(feature) ? <feature.Component /> : <AccessDenied />;
}

export default RouterSlots;
