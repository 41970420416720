// @flow
import { COPY_PAYMENT_DETAILS_INFO } from "../../../../../../helpers/constants/manageAffiliate";

const separator = "->";

/**
 * Decline info text map, key must be separated from status -> to status. Order is important, first check be getting
 * @example
 * 'Approve->Decline': 'text1' // exact status change
 * '->Decline': 'text1'// any status before to exact status now
 * 'Approve->': 'text1'// exact status before to any status now
 */
const statusInfoTextMap = {
  [`Approve${separator}Approve`]: "",
  [`Decline${separator}Decline`]: "",
  [`Suspended${separator}Suspended`]: "",
  [`Hold${separator}Hold`]: "",

  [`Approve${separator}Decline`]: `Upon changing an affiliate's status from Approve to Decline, several adjustments take place:
 - The Min payment field drops to 0 and the Payment available field changes to 'yes'.
 - The affiliate is denied any access to the account.
 - All new affiliate conversions will be declined for the "Declined aff" reason.
 - ${COPY_PAYMENT_DETAILS_INFO}`,

  [`Pending${separator}Decline`]: `Upon changing an affiliate's status to Declined, several adjustments take place:
 - The affiliate is denied any access to the account.
 - All new affiliate conversions will be declined for the "Declined aff" reason.`,

  [`Approve${separator}Suspended`]: `Upon changing an affiliate's status from Approve to Suspended, several adjustments take place:
 - The Min payment field drops to 0 and the Payment available field changes to 'yes'.
 - The affiliate is denied any access to the account.
 - ${COPY_PAYMENT_DETAILS_INFO}`,

  [`Approve${separator}Hold`]: `Upon changing an affiliate's status from Approve to Hold, several adjustments take place:
 - The affiliate has access to the account, with no editing privileges.
 - All new affiliate conversions will be declined for the "Declined aff" reason.
 - ${COPY_PAYMENT_DETAILS_INFO}`,

  [`Approve${separator}`]: `Upon changing an affiliate's status from Approve, several adjustments take place:
 - ${COPY_PAYMENT_DETAILS_INFO}`,

  [`${separator}Decline`]: `Upon changing an affiliate's status to Declined, several adjustments take place:
 - The Min payment field drops to 0 and the Payment available field changes to 'yes'.
 - The affiliate is denied any access to the account.
 - All new affiliate conversions will be declined for the "Declined aff" reason.`,

  [`${separator}Suspended`]: `Upon changing an affiliate's status to Suspended, several adjustments take place:
 - The Min payment field drops to 0 and the Payment available field changes to 'yes'.
 - The affiliate is denied any access to the account.`,
}

export function getChangeStatusInfoText(initialStatus, status) {
  const currentKeys = [initialStatus, status];
  const key = Object.keys(statusInfoTextMap)
    .find((k) => k
      .split(separator)
      .map((reg, index) => new RegExp(reg).test(currentKeys[index]))
      .every(i => i));
  return statusInfoTextMap[key] || "";
}
